import React from "react";
import { Amplify } from "aws-amplify";
import { SignIn } from "@torc-robotics/mcli-mfui-auth";
import awsExports from "./aws-config";

Amplify.configure(awsExports);

export default function Root({ children }) {
  return <SignIn>{children}</SignIn>;
}
